var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.presc.id
      ? _c("div", { staticClass: "no-data" }, [
          _c("div", [_vm._v("加载中...")]),
        ])
      : _vm._e(),
    _vm.presc.id
      ? _c("div", { staticClass: "wrap" }, [
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "h-bg" }),
            _c("div", { staticClass: "order radius mt-8" }, [
              _c("div", { staticClass: "pre-id flex f-jc-sb" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(
                      "处方ID：" + _vm._s(_vm.cutdownStr(_vm.presc.orderId, 20))
                    ),
                  ]),
                  _c(
                    "i",
                    { staticClass: "copy", on: { click: _vm.handleCopy } },
                    [_vm._v("复制")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "pre-status" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.presc.status === 1
                        ? "待支付"
                        : _vm.presc.status === 2
                        ? "待收货"
                        : _vm.presc.status === 3
                        ? "已完成"
                        : _vm.presc.status === 4
                        ? "已取消"
                        : _vm.presc.status === 5
                        ? "待发货"
                        : _vm.presc.status === 6
                        ? "已退款"
                        : "未知状态"
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "prescription radius mt-8" }, [
            _c("div", { staticClass: "top flex f-jc-sb" }, [
              _c("div", { staticClass: "left" }, [
                _c("p", [_vm._v("互联网诊疗平台处方笺")]),
                _c("span", [
                  _vm._v(
                    "日期：" + _vm._s(_vm.formatDate(_vm.presc.createdAt))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "right" }, [_vm._v("普通药品处方")]),
            ]),
            _c("div", { staticClass: "bottom" }, [
              _c("span", { staticClass: "mr" }, [
                _c("i", [_vm._v("姓名")]),
                _c("i", [_vm._v(_vm._s(_vm.presc.patientName))]),
              ]),
              _c("span", { staticClass: "mr" }, [
                _c("i", [_vm._v("性别")]),
                _c("i", [_vm._v(_vm._s(_vm.presc.gender === 1 ? "男" : "女"))]),
              ]),
              _c("span", [
                _c("i", [_vm._v("年龄")]),
                _c("i", [_vm._v(_vm._s(_vm.presc.age))]),
              ]),
              _c("span", [
                _c("i", [_vm._v("科室")]),
                _c("i", [_vm._v(_vm._s(_vm.presc.deptName))]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "zd radius mt-8" }, [
            _c("p", { staticClass: "title" }, [_vm._v("诊断")]),
            _c("div", { staticClass: "flex" }, [
              _vm.presc.xyzd
                ? _c("span", [_vm._v("西医诊断：" + _vm._s(_vm.presc.xyzd))])
                : _vm._e(),
              _c("span", [_vm._v("中医诊断：" + _vm._s(_vm.presc.zyzd))]),
              _c("span", [_vm._v("中医证型：" + _vm._s(_vm.presc.zybz))]),
            ]),
          ]),
          _c("div", { staticClass: "kf radius mt-8" }, [
            _c("p", { staticClass: "title" }, [_vm._v("开方")]),
            _c("span", [_vm._v("服用方式：" + _vm._s(_vm.presc.fyff))]),
            _c("span", [
              _vm._v("剂型选择：" + _vm._s(_vm.presc.dosageFormName)),
            ]),
            _vm.presc.dosageForm !== "2"
              ? _c("span", [
                  _vm._v("代煎设置：" + _vm._s(_vm.presc.dosageFormSubName)),
                ])
              : _vm._e(),
            _c("div", { staticClass: "detail" }, [
              _vm._v(" " + _vm._s(_vm.presc._rp)),
              _c("br"),
              _vm._v(
                " 共" +
                  _vm._s(_vm.presc.jiliang) +
                  "剂，每日" +
                  _vm._s(_vm.presc.jlPerDay) +
                  "剂，1剂分" +
                  _vm._s(_vm.presc.times) +
                  "次服用 "
              ),
            ]),
          ]),
          _c("div", { staticClass: "yz radius mt-8" }, [
            _c("p", { staticClass: "title" }, [_vm._v("医嘱")]),
            _c("span", [_vm._v(_vm._s(_vm.presc.yz))]),
          ]),
          _vm.presc.status === 1
            ? _c(
                "div",
                { staticClass: "pay flex f-jc-sb f-ai-c" },
                [
                  _c("s-button", {
                    attrs: { btnText: "去结算" },
                    on: { "btn-click": _vm.pay },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }