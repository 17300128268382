<template>
	<div>
		<div class="no-data" v-if="!presc.id">
			<div>加载中...</div>
		</div>
		<div class="wrap" v-if="presc.id">
			<div class="header">
				<div class="h-bg"></div>
				<div class="order radius mt-8">
					<div class="pre-id flex f-jc-sb">
						<div>
							<span>处方ID：{{cutdownStr(presc.orderId, 20)}}</span>
							<i class="copy" @click="handleCopy">复制</i>
						</div>
						<!-- <i class="icon"></i> -->
					</div>
					<div class="pre-status">
						{{
							presc.status === 1 ? '待支付' :
							presc.status === 2 ? '待收货' :
							presc.status === 3 ? '已完成' :
							presc.status === 4 ? '已取消' :
							presc.status === 5 ? '待发货' :
							presc.status === 6 ? '已退款' : '未知状态'
						}}
					</div>
				</div>
			</div>
			<div class="prescription radius mt-8">
				<div class="top flex f-jc-sb">
					<div class="left">
						<p>互联网诊疗平台处方笺</p>
						<span>日期：{{formatDate(presc.createdAt)}}</span>
					</div>
					<div class="right">普通药品处方</div>
				</div>
				<div class="bottom">
					<span class="mr">
						<i>姓名</i>
						<i>{{presc.patientName}}</i>
					</span>
					<span class="mr">
						<i>性别</i>
						<i>{{presc.gender === 1 ? '男' : '女'}}</i>
					</span>
					<span>
						<i>年龄</i>
						<i>{{presc.age}}</i>
					</span>
					<span>
						<i>科室</i>
						<i>{{presc.deptName}}</i>
					</span>
				</div>
			</div>
			<div class="zd radius mt-8">
				<p class="title">诊断</p>
				<div class="flex">
					<span v-if="presc.xyzd">西医诊断：{{presc.xyzd}}</span>
					<span>中医诊断：{{presc.zyzd}}</span>
					<span>中医证型：{{presc.zybz}}</span>
				</div>
			</div>
			<div class="kf radius mt-8">
				<p class="title">开方</p>
				<span>服用方式：{{presc.fyff}}</span>
				<span>剂型选择：{{presc.dosageFormName}}</span>
				<span v-if="presc.dosageForm !== '2'">代煎设置：{{presc.dosageFormSubName}}</span>
				<div class="detail">
					{{presc._rp}}<br/>
					共{{presc.jiliang}}剂，每日{{presc.jlPerDay}}剂，1剂分{{presc.times}}次服用
				</div>
			</div>
			<div class="yz radius mt-8">
				<p class="title">医嘱</p>
				<span>{{presc.yz}}</span>
			</div>
			<div v-if="presc.status === 1" class="pay flex f-jc-sb f-ai-c">
				<s-button btnText="去结算" @btn-click="pay"></s-button>
			</div>
		</div>
	</div>
</template>
<script>
import sButton from '@/components/Button';
import { prescriptionDetail, prescriptionDetailBind } from '@/request/api/user';
import { cutdownString } from '@/utils';
import { dateFormat } from '@/utils/time';

export default {
	name: 'prescriptionDetail',
	data () {
		return {
			presc: {}
		};
	},
	components: { sButton },
	created () {
		if (this.$route.path === '/pdr') {
			this.getPrescriptionDetailNew();
		} else {
			this.getPrescriptionDetail();
		}
	},
	mounted () {
		// if (window.history && window.history.pushState) {
		// history.pushState(null, null, document.URL);
		// window.addEventListener('popstate', this.onBack, false);
		// }
	},
	destroyed () {
		// window.removeEventListener('popstate', this.onBack, false);
	},
	methods: {
		onBack () {
			this.showGoBack = true;
			// this.$root.go('user-center');
			// this.$root.go(-1);
		},
		formatDate (date) {
			return dateFormat(new Date(date), 'yyyy-MM-dd');
		},
		handleCopy (e) {
			const transfer = document.createElement('input');
			document.body.appendChild(transfer);
			transfer.value = this.presc.orderId; // 这里表示想要复制的内容
			transfer.focus();
			transfer.select();
			if (document.execCommand('copy')) {
				document.execCommand('copy');
			}
			transfer.blur();
			// console.log('复制成功');
			this.$root.elMsg('复制成功');
			document.body.removeChild(transfer);
		},
		pay () {
			if (!this.presc.appointmentId) {
				this.$root.go(`/fillin-order?id=${this.presc.id}`);
			} else {
				this.$root.go(`/appointment-detail?id=${this.presc.appointmentId}`);
			}
		},
		cutdownStr (str, length) {
			return cutdownString(str, length);
		},
		// 医生主动开方-查询处方单详情
		getPrescriptionDetailNew () {
			const search = new URLSearchParams(window.location.search);
			const key = Array.from(search.keys());
			prescriptionDetailBind({
				id: key[0]
			}).then(data => {
				if (data && data === 'retry') {
					this.getPrescriptionDetailNew();
				} else if (data) {
					const _rp = [];
					data.rp = JSON.parse(data.rp);
					data.rp.forEach(r => {
						let name = `${r.name}`;
						if (data.status === 2 || data.status === 3 || data.status === 5 || (data.status === 1 && data.payFirst === 0)) {
							name += `${r.count}${r.unit}`;
						}
						_rp.push(`${name}`); // .split('/')[1]
					});
					data._rp = _rp.join(' ');
					this.presc = data;
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		getPrescriptionDetail () {
			prescriptionDetail({
				id: this.$route.query.id
			}).then(data => {
				// console.log('prescriptionDetail===', data);
				if (data && data === 'retry') {
					this.getPrescriptionDetail();
				} else if (data) {
					const _rp = [];
					data.rp = JSON.parse(data.rp);
					data.rp.forEach(r => {
						let name = `${r.name}`;
						if (data.status === 2 || data.status === 3 || data.status === 5 || (data.status === 1 && data.payFirst === 0)) {
							name += `${r.count}${r.unit}`;
						}
						_rp.push(`${name}`); // .split('/')[1]
					});
					data._rp = _rp.join(' ');
					this.presc = data;
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		}
	}
};
</script>
<style lang="less" scoped>
	.wrap {
		padding-bottom: 100px;
	}
	.no-data {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 15px;
	}
	.title {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #232830;
	}
	.header {
		position: relative;
		padding-top: 30px;
		.h-bg {
			height: 80px;
			background: linear-gradient(134deg, #FF7F4E 0%, #FF6453 100%);
			position: absolute;
			top: 0;
			left: -12px;
			right: -12px;
		}
	}
	.radius {
		background: #FFFFFF;
		box-shadow: 0px 4.5px 18px -4px rgba(16, 31, 46, 0.04);
		border-radius: 8px;
		border: .5px solid #EFEFEF;
		width: 100%;
	}
	.mt-8 {
		margin-top: 8px;
	}
	.order {
		box-sizing: border-box;
		position: relative;
		padding: 15px;
		.pre-id {
			span {
				font-size: 13px;
			}
			.copy {
				width: 28px;
				height: 14px;
				border: 1px solid #7D7D7D;
				border-radius: 2px;
				margin-left: 6px;
				font-size: 12px;
				line-height: 14px;
				text-align: center;
			}
			.icon {
				width: 15px;
				height: 15px;
				background: url('../../assets/images/comma.png') no-repeat;
				background-size: contain;
				display: block;
			}
		}
		.pre-status {
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #FF8D16;
			margin-top: 18.5px;
		}
	}
	.prescription {
		padding: 15px 10px 18px 12px;
		box-sizing: border-box;
		.top {
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			.left {
				p {
					font-size: 20px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #232830;
				}
				span {
					font-size: 12px;
					color: #7D7D7D;
					margin-top: 10px;
				}
			}
			.right {
				width: 50px;
				height: 44px;
				border-radius: 2px;
				opacity: 0.56;
				border: 0.5px solid #FF6450;
				color: #FF6450;
				font-size: 12px;
				padding: 5px;
				box-sizing: border-box;
			}
		}
		.bottom {
			background: #F9FAFB;
			padding: 15px 15px 7px 15px;
			margin-top: 20px;
			span {
				min-width: 58px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				margin-bottom: 13px;
				&.mr {
					margin-right: 46px;
				}
				i {
					&:nth-child(1) {
						color: #7D7D7D;
						margin-right: 10px;
					}
				}
			}
		}
	}
	.zd {
		padding: 20px 0 20px 16px;
		box-sizing: border-box;
		div {
			flex-direction: column;
			margin-top: 15px;
			color: #7D7D7D;
			font-size: 14px;
			word-break: break-word;
		}
	}
	.kf {
		padding: 20px 0 20px 16px;
		box-sizing: border-box;
		display: flex;
    flex-direction: column;
		p {
			margin-bottom: 18px;
		}
		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FAA74F;
		}
		.detail {
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #7D7D7D;
			margin: 25px 0;
			word-break: break-word;
		}
	}
	.yz {
		padding: 20px 0 20px 15px;
		// margin-bottom: 80px;
		box-sizing: border-box;
		p {
			margin-bottom: 15px;
		}
		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #7D7D7D;
			word-break: break-word;
		}
	}
	.pay {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background: #FFFFFF;
		height: 52px;
		// padding: 0 12px;
		width: 100%;
		/deep/ .ssl-btn {
			width: 100%;
			margin: 0 12px;
		}
	}
</style>