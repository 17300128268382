import { render, staticRenderFns } from "./prescription-detail.vue?vue&type=template&id=391b2ef9&scoped=true&"
import script from "./prescription-detail.vue?vue&type=script&lang=js&"
export * from "./prescription-detail.vue?vue&type=script&lang=js&"
import style0 from "./prescription-detail.vue?vue&type=style&index=0&id=391b2ef9&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "391b2ef9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev/fe/ssl-odat-patient-wechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('391b2ef9')) {
      api.createRecord('391b2ef9', component.options)
    } else {
      api.reload('391b2ef9', component.options)
    }
    module.hot.accept("./prescription-detail.vue?vue&type=template&id=391b2ef9&scoped=true&", function () {
      api.rerender('391b2ef9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/prescription-detail.vue"
export default component.exports